import React from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';

export const styles = theme => ({
  root: {
    ...theme.typography.body1,
    color: theme.palette.primary[theme.palette.mode],
    '&:hover': {
      textDecoration: 'underline',
    },
    '&disabled': {
      color: theme.palette.action.disabled,
    },
    cursor: 'pointer',
  },
  colorInherit: {
    color: 'inherit',
  },
  label: {
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
});

function Anchor(props) {
  const theme = useTheme();
  const {
    children,
    disabled,
    ...other
  } = props;
  return (
    <a className="anchor" disabled={disabled} {...other}>
      <Typography variant="body1" component="span">{children}</Typography>
    </a>
  );
}

Anchor.propTypes = {
  /**
   * The content of the button.
   */
  children: PropTypes.node.isRequired,
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The URL to link to when the button is clicked.
   * If defined, an `a` element will be used as the root node.
   */
  href: PropTypes.string,
  /**
   * If `true`, and `variant` is `'fab'`, will use mini floating action button styling.
   */
};

Anchor.defaultProps = {
  color: 'default',
  disabled: false,
  className: undefined,
  href: undefined,
};

export default Anchor;
