import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { deepmerge } from '@mui/utils';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Alert, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { isMobile } from 'react-device-detect';
import { useMeasure } from 'react-use';
import './App.css';
import Authenticate from './components/authenticate';
import NoPath from './components/NoPath';
import Decision from './components/decision';
import LanguageSelector from './components/LanguageSelector';
import OIDCTokenAcquired from './components/authenticate/OIDCTokenAcquired';
import ResetPassword from './components/reset-password';
import SelectAccount from './components/select-account';
import SetServer from './components/set-server';
import EmailResetCode from './components/email-reset-code';
import { MenuTitle, Panel } from './components/util';
import { toErrorMessage } from './lib/util';
import * as settings from './modules/settings';
import * as rest from './modules/rest';
import * as login from './modules/login';
import * as error from './modules/error';

const themeOptions = {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#606070',
      dark: '#404050',
      darkDialogBackground: '#7f7f8c',
      lightDialogBackground: '#fff',
      darkGradient: 'linear-gradient(to bottom right, #404050, #505060)',
      contrastText: '#fff',
      darkContrastText: '#fff',
      lightContrastText: '#000',
    },
    secondary: {
      main: '#ffab40',
      contrastText: '#000000',
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiTab: {
      root: {
        minWidth: 'unset',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          backgroundColor: '#448',
        },
      },
    },
  },
};

function colorToRgbParser(cssColor) {
  const div = document.createElement('div');
  div.id = 'for-computed-style';

  div.style.color = cssColor;

  // appending the created element to the DOM
  document.querySelector('body').appendChild(div);

  const match = getComputedStyle(div).color.match(/^rgba?\s*\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*(\d\.\d)\s*)?\)$/i);

  // removing element from the DOM
  document.querySelector('#for-computed-style').remove();

  if (match) {
    // match[0] is regex complete match (e.g. "rgb(0,0,0)"), not a regex capturing group
    let parsedColor = {
      r: match[1],
      g: match[2],
      b: match[3]
    };
    if (match[4]) { // if alpha channel is present
      parsedColor.a = match[4];
    }
    return parsedColor;
  } else {
    throw new Error(`Color ${cssColor} could not be parsed.`);
  }
}

function mkGradient({r, g, b, a}) {
  return `linear-gradient(to bottom right, rgba(${r}, ${g}, ${b}, ${a || 100}%), rgba(${1*r + 16}, ${1*g + 16}, ${1*b + 16}, ${a || 100}%))`;
}

const themeModes = ['main', 'dark', 'light'];
function addGradients(theme) {
  Object.keys(theme.palette).forEach(palette => {
    themeModes.forEach(mode => {
      if (theme.palette[palette][mode] && !theme.palette[palette][`${mode}Gradient`]) {
        const color = colorToRgbParser(theme.palette[palette][mode]);
        theme.palette[palette][`${mode}Gradient`] = mkGradient(color);
      }
    });
  });
  return theme;
}

const lightTheme = addGradients(createTheme(themeOptions));

const darkTheme = addGradients(createTheme(deepmerge(themeOptions, {
  palette: {
    mode: 'dark',
  },
})));

function fixMuiTabBreakpoint(theme) {
  // Need to do this after creating the theme, so we have access to the
  // breakpoints
  theme.overrides.MuiTab.root[theme.breakpoints.up('md')] = {
    minWidth: 'unset',
  };
}

fixMuiTabBreakpoint(lightTheme);
fixMuiTabBreakpoint(darkTheme);

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

function NoMatch() {
  const location = useLocation();
  return (
    <div>
      <h3 className="error">
        404 NOT FOUND
      </h3>
    </div>
  );
}


function TestPage() {
  const theme = useTheme();
  const { t } = useTranslation();

  const info = 'This is an info message';
  const error = 'This is an error message';
  let infoMsg = info ? <Typography sx={{ background: theme.palette.info[theme.palette.mode], color: theme.palette.info.contrastText }}>{toErrorMessage(t, info)}</Typography> : null;
  let err = error ? <Typography sx={{ background: theme.palette.error[theme.palette.mode], color: theme.palette.error.contrastText }}>{toErrorMessage(t, error)}</Typography> : null;

  infoMsg = <Alert severity='info'>{info}</Alert>;
  err = <Alert severity='error'>{error}</Alert>;

  return (
    <div>
      <div>
        {infoMsg}
      </div>
      <div>
        {err}
      </div>
    </div>
  );
}

function areCookiesEnabled() {
  try {
    document.cookie = 'cookietest=1';
    var cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return cookiesEnabled;
  } catch (e) {
    return false;
  }
}

function showError(error) {
  // Make the payload available as a string, for error messages
  error.payloadString = JSON.stringify(error.payload);
  return t => {
    let title = t(`error-${error.error}-title`, error);
    if (title === `error-${error.error}-title`) {
      title = `${t('Error')} - ${error.error}`;
    }
    let body = t(`error-${error.error}-body`, error);
    if (body === `error-${error.error}-body`) {
      body = `Error ${error.error} encountered. This was unexpected. Go back to the application you tried to log in to, and try again. If that fails, please contact GroupTalk support.`;
    }
  
    return <div id="auth">
      <MenuTitle>
        <Typography variant='h6'>{title}</Typography>
      </MenuTitle>
      <Panel>
        {body}
      </Panel>
    </div>
  };
}

function toOktaOptions(oidcProvisioning) {
  return {
    issuer: oidcProvisioning.issuer,
    clientId: oidcProvisioning.clientId,
    redirectUri: window.location.origin,
  };
}

function App({ initCompleted, style, redirecting, showLang, logo, error, oidcProvisioning }) {
  if (!initCompleted) {
    return null;
  }

  const navigate = useNavigate();
  const [ref, { height }] = useMeasure();
  const { t, i18n } = useTranslation();
  const query = new URLSearchParams(window.location.search.substring(1));
  const queryError = query.get('error');
  const error_description = query.get('error_description');
  const error_hint = query.get('error_hint');
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  let content;
  if (queryError) {
    content = t => <>
      <h1>{t(queryError)}</h1>
      <div>
        {t(error_description)}
      </div>
      <div className="hint">
        {t(error_hint)}
      </div>
    </>;
  } else if (error) {
    content = showError(error);
  } else if (areCookiesEnabled()) {
    content = t => <>
      <Routes>
        <Route path="/" element={<NoPath />} />
        <Route path='server' element={<SetServer />} />
        <Route path='login/callback' element={<LoginCallback />} />
        <Route path='login/oidc' element={<OIDCTokenAcquired />} />
        <Route path="login/*" element={<Authenticate />} />
        <Route path="consent" element={<Decision />} />
        <Route path="email-reset" element={<EmailResetCode />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="select" element={<SelectAccount footerHeight={height}/>} />
        <Route path="t3st1ng" element={<TestPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>;
  } else {
    content = t => (
      <div className="error">
        {t('Cookies seem to be turned off. GroupTalk login requires cookie support, please turn them on and reload this page.')}
      </div>
    );
  }

  const theme = isMobile ? darkTheme : lightTheme;
  const styleLink = style
    ? (<link rel='stylesheet' type='text/css' href={process.env.PUBLIC_URL + '/' + style} />)
    : (<span />);
  const app = (
    <div className="App">
      <Paper elevation={0} className="App-header">
        <div className="App-logo">
          <img src={logo} alt="Logo" style={{ verticalAlign: 'sub' }} />
        </div>
        {redirecting ? (<div>
          <Alert severity="success">{t('Redirecting, please wait...')}</Alert>
        </div>) : (
            <>
              {content(t)}
              <div ref={ref}>
                { showLang && <LanguageSelector translate={t} i18n={i18n} /> }
              </div>
            </>
          )}
      </Paper>
    </div>
  );
  const wrappedApp = oidcProvisioning && oidcProvisioning.type === "okta"
    ? (<Security oktaAuth={new OktaAuth(toOktaOptions(oidcProvisioning))} restoreOriginalUri={restoreOriginalUri}>{app}</Security>)
    : app;

  return (
    <ThemeProvider theme={theme}>
      {styleLink}
      {wrappedApp}
    </ThemeProvider>
  );
}

// App.propTypes = {
//   /* eslint-disable react/forbid-prop-types */
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.object.isRequired,
// };


App = connect(
  state => ({
    style: settings.getStyle(state),
    initCompleted: login.getInitCompleted(state),
    redirecting: login.getRedirecting(state),
    showLang: settings.getShowLang(state),
    logo: settings.getLogo(state),
    error: error.getError(state),
    oidcProvisioning: settings.getOIDCProvisioning(state),
  })
)(App);

const defaultTheme = createTheme();

export default function ThemedApp() {
  return <ThemeProvider theme={defaultTheme}><App /></ThemeProvider>;
}
