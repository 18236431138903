import { handleAction, handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getSiteLink } from '../lib/util';
import * as rest from './rest';
import * as settings from './settings';

const MODULE = 'login';

export const INIT_COMPLETED = `${MODULE} : Init completed`;
export const REDIRECTING = `${MODULE} : Redirecting`;
export const LOGIN_MODE_SET = `${MODULE} : Login mode set`;
export const LOGIN_REQUEST = `${MODULE} : Login request`;
export const ERROR_SET = `${MODULE} : Show login error`;
export const ERROR_RESET = `${MODULE} : Reset login error`;
export const INFO_SET = `${MODULE} : Show login info`;
export const INFO_RESET = `${MODULE} : Reset login info`;
export const RESEND_OOB = `${MODULE} : resend OOB`;
export const PASSWORD_EXPIRED_SET = `${MODULE} : Password expired set`;
export const TOTP_QR_CODE = `${MODULE} : TOTP QR Code`;
export const TOTP_SECRET = `${MODULE} : TOTP Secret`;
export const REQUIRE_2FA = `${MODULE} : Require 2FA`;
export const USER_UUID = `${MODULE} : User UUID`;
export const USER_NAME = `${MODULE} : User name`;
export const AD_ACCOUNT = `${MODULE} : AD account`;
export const AD_ACCOUNT_NOT_IN_GROUPTALK = `${MODULE} : AD account not in GroupTalk`;
export const UNKNOWN_CODE = `${MODULE}: Unknown code`;
export const RETRY_OTP = `${MODULE}: Retry OTP`;
export const SESSIONS_SET = `${MODULE}: Sessions set`;
export const SESSION_SET = `${MODULE}: Session set`;
export const DOMAIN_SET = `${MODULE}: Domain set`;
export const OIDC_AUTH_DONE  = `${MODULE}: OIDC authorization is done`;

// Selectors
export const getInitCompleted = state => state[MODULE].initCompleted;
export const getRedirecting = state => state[MODULE].redirecting;
export const getLoginMode = state => state[MODULE].mode;
export const getError = state => state[MODULE].error;
export const getInfo = state => state[MODULE].info;
export const getPasswordExpired = state => state[MODULE].passwordExpired;
export const getLoginData = state => state[MODULE].loginData;
export const getExternal = state => state[MODULE].external;
export const getExternalStatus = state => state[MODULE].externalStatus;
export const getQrCode = state => state[MODULE].qrCode;
export const getTOTPSecret = state => state[MODULE].totpSecret;
export const requires2FA = state => state[MODULE].require2FA;
export const getUserUuid = state => state[MODULE].userUuid;
export const getUserName = state => state[MODULE].userName;
export const getADAccount = state => state[MODULE].adAccount;
export const isADAccountNotInGT = state => state[MODULE].adNotInGT;
export const isUnknownCode = state => state[MODULE].unknownCode;
export const isRetryingOTP = state => state[MODULE].retryOTP;
export const getSessions = state => state[MODULE].sessions;
export const getDomain = state => state[MODULE].domain;
export const getOIDCAuthDone = state => state[MODULE].oidcAuthDone;

// Actions
export const initCompleted = () => ({ type: INIT_COMPLETED });
export const redirecting = () => ({ type: REDIRECTING });
export const setPasswordExpired = email => ({ type: PASSWORD_EXPIRED_SET, email });
export const setLoginMode = mode => ({ type: LOGIN_MODE_SET, mode });
export const setError = error => ({ type: ERROR_SET, error });
export const resetError = () => ({ type: ERROR_RESET });
export const setInfo = payload => ({ type: INFO_SET, payload });
export const resetInfo = () => ({ type: INFO_RESET });
export const requestLogin = (mode, oob, body, options) => ({ type: LOGIN_REQUEST, mode, oob, body, options });
export const resendOOB = mode => ({ type: RESEND_OOB, mode });
export const totpQrCode = qrCode => ({ type: TOTP_QR_CODE, qrCode });
export const setTOTPSecret = secret => ({ type: TOTP_SECRET, secret });
export const setRequire2FA = payload => ({ type: REQUIRE_2FA, payload });
export const setUserUuid = payload => ({ type: USER_UUID, payload });
export const setUserName = payload => ({ type: USER_NAME, payload });
export const setADAccount = payload => ({ type: AD_ACCOUNT, payload });
export const setADAccountNotInGT = payload => ({ type: AD_ACCOUNT_NOT_IN_GROUPTALK, payload });
export const setUnknownCode = payload => ({ type: UNKNOWN_CODE, payload });
export const setRetryingOTP = payload => ({ type: RETRY_OTP, payload });
export const setSessions = payload => ({ type: SESSIONS_SET, payload });
export const setSession = payload => ({ type: SESSION_SET, payload });
export const setDomain = payload => ({ type: DOMAIN_SET, payload });
export const setOIDCAuthDone = payload => ({ type: OIDC_AUTH_DONE, payload });

export const completeLogin = data => (dispatch) => {
  dispatch(rest.verifyToken(data));
};
export const verify2FACode = (code) => rest.restRequest({}, state => {
  const body = JSON.stringify({
    code,
    secret: getTOTPSecret(state),
    userUuid: getUserUuid(state),
    challenge: rest.getChallenge(state),
    remember: settings.getRememberAsEnum(state),
  });
  return {
    url: `${getSiteLink('openid')}/authentication/api/auth/login/totp-code`,
    options: { method: 'POST', body },
    successDispatch: (dispatch, data) => {
      dispatch(rest.authenticateSuccess(data));
      return data;
    },
    failDispatch: (dispatch, error) => {
      const payload = { body };
      if (error.json) {
        payload.json = error.json;
      } else {
        payload.error = error;
      }
      dispatch(rest.authenticateFailure(payload));
      return null;
    },
  };
});


// Reducer
export default {
  [MODULE]: combineReducers({
    mode: handleAction(LOGIN_MODE_SET, (_, action) => action.mode, 'password'),
    error: handleActions(
      {
        [ERROR_SET]: (_, action) => action.error,
        [ERROR_RESET]: () => null,
      },
      null,
    ),
    info: handleActions(
      {
        [INFO_SET]: (_, action) => action.payload,
        [INFO_RESET]: () => null,
      },
      null,
    ),
    passwordExpired: handleActions(
      {
        [PASSWORD_EXPIRED_SET]: () => true,
        [ERROR_RESET]: () => false,
      },
      false,
    ),
    loginData: handleAction(LOGIN_REQUEST, (_, action) => action.body, null),
    initCompleted: handleAction(INIT_COMPLETED, () => true, false),
    redirecting: handleAction(REDIRECTING, () => true, false),
    qrCode: handleAction(TOTP_QR_CODE, (_, action) => action.qrCode, null),
    totpSecret: handleAction(TOTP_SECRET, (_, action) => action.secret, null),
    require2FA: handleAction(REQUIRE_2FA, (_, action) => action.payload, false),
    userUuid: handleAction(USER_UUID, (_, action) => action.payload, null),
    userName: handleAction(USER_NAME, (_, action) => action.payload, null),
    adAccount: handleAction(AD_ACCOUNT, (_, action) => action.payload, null),
    adNotInGT: handleAction(AD_ACCOUNT_NOT_IN_GROUPTALK, (_, action) => action.payload, false),
    unknownCode: handleAction(UNKNOWN_CODE, (_, action) => action.payload, false),
    retryOTP: handleAction(RETRY_OTP, (_, action) => action.payload, true),
    sessions: handleAction(SESSIONS_SET, (_, action) => action.payload, {}),
    session: handleAction(SESSION_SET, (_, action) => action.payload, null),
    domain: handleAction(DOMAIN_SET, (_, action) => action.payload, null),
    oidcAuthDone: handleAction(OIDC_AUTH_DONE, (_, action) => action.payload, false),
  }),
};
