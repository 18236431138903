import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: '5d919fc3-9f72-4bf6-a56c-784d5a3ca245'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback(level, message, containsPii) {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: msal.LogLevel.Info,
      piiLoggingEnabled: false
    },
  },
};

export function getMSALConfig() {
  return msalConfig;
}

export let msalInstance;

export function createMSALInstance(authority) {
  if (authority) {
    msalConfig.auth.authority = authority;
  }
  msalInstance = new msal.PublicClientApplication(msalConfig);
  return Promise.resolve(msalInstance); // msalInstance.initialize().then(() => msalInstance);
}

const loginRequest = {
  scopes: ["openid", "profile"],
  // Always prompt for AD account to use
  prompt: 'select_account',
};

export function getLoginRequest() {
  return loginRequest;
}
