import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as validations from '../lib/validations';
import * as login from '../modules/login';
import * as settings from '../modules/settings';
import * as resetPassword from '../modules/reset-password';
import * as rest from '../modules/rest';

function FormFunc({ onSubmit, email, button, passwordExpired, disabled }) {
  const { t } = useTranslation();
  const buttonText = button || t('Reset password');

  return (
    <Form onSubmit={onSubmit} initialValues={{ email }}>
      {({ handleSubmit }) => (
        <form
          name="resetCodeForm"
          onSubmit={handleSubmit}
        >
          <div className="flex">
            {passwordExpired && (<h2>{t('Your password has expired')}</h2>)}
            <div className="layout-align-center-stretch layout-row">
              <TextField
                className='flex-85'
                id="email"
                name="email"
                inputProps={{
                  inputMode: 'email',
                  autoCorrect: 'false',
                  autoCapitalize: 'none',
                }}
                label={t('Email')}
                required
                fieldProps={{
                  validate: validations.composeValidators(validations.required, validations.email)}}
                margin="normal"
              />
              <span className="invalid-feedback"></span>
            </div>
          </div>
          <div className="layout-row">
            <div className="flex-20" />
            <Button
              disabled={disabled}
              type="submit"
              disableRipple
              variant="contained"
              color="primary"
              className="raisedPrimary flex-60"
              style={{ margin: '6px 0px' }}
            >
              {buttonText}
            </Button>
            <div className="flex-20" />
          </div>
        </form>
      )}
    </Form>
  );
};

const InternalForm = connect(state => ({
  passwordExpired: login.getPasswordExpired(state),
  email: settings.getEmail(state),
}))(FormFunc);

function EmailResetCode({
  email,
  emailStatus,
  emailResetCode,
  resetEmailResetCode,
  isStrict,
  loginMode,
  setEmail,
  hasChallenge,
  button,
  onSubmit,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleSubmit({ email }) {
    onSubmit && onSubmit({ email });
    setEmail(email);
    emailResetCode(email);
  }

  function handleResend() {
    resetEmailResetCode();
    // NOTE: This relies on a middleware that automatically updates
    // setting.email whenever this form's email field is updated
    handleSubmit({ email });
  }

  function handleBackToLogin() {
    resetEmailResetCode();
    // When on mobile, and using strict setting, go directly to the selected login mode
    const url = (isMobile && isStrict)
      ? `/login/${loginMode}`
      : '/login';
    navigate(url);
  }

  if (emailStatus === resetPassword.STATUS_SUCCESS) {
    return <>
      <h1>{t('Email sent!')}</h1>
      <p>{t('Open the email and click on the button in it to change your password')}</p>
      <Button onClick={handleResend}>
        {t('Resend email')}
      </Button>
    </>;
  } else {
    let statusMessage;
    switch (emailStatus) {
      case resetPassword.STATUS_NONE:
        statusMessage = <></>;
        break;
      case resetPassword.STATUS_SENDING:
        statusMessage = <Alert sx={{ alignSelf: 'center' }} severity="info">{t('Sending email...')}</Alert>
        break;
      case resetPassword.STATUS_FAIL:
        statusMessage = <Alert sx={{ alignSelf: 'center' }} severity="error">{t('Failed to send email')}</Alert>
        break;
      default:
        statusMessage = <Alert sx={{ alignSelf: 'center' }} severity="error">{t('Unknown email error!')}</Alert>
        break;
    }
    return (
      <>
        <InternalForm onSubmit={handleSubmit} button={button} translate={t} disabled={emailStatus === resetPassword.STATUS_SENDING} />
        {statusMessage}
        {hasChallenge &&
          <Button onClick={handleBackToLogin}>
            {t('Back to login')}
          </Button>
        }
      </>
    );
  }
}

EmailResetCode = connect(state =>
  ({
    emailStatus: resetPassword.getEmailStatus(state),
    email: settings.getEmail(state),
    isStrict: settings.getStrict(state),
    hasChallenge: rest.getChallenge(state) !== null,
    loginMode: login.getLoginMode(state),
  }), {
    resetEmailResetCode: resetPassword.resetEmailResetCode,
    emailResetCode: resetPassword.emailResetCode,
    setEmail: settings.setEmail,
  }
)(EmailResetCode);

export default EmailResetCode;
