import React, { useCallback, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { isMobile } from "react-device-detect";
import { MSLogin } from "../../icons";
import { msalInstance, getLoginRequest } from "../../msal-config";
import AuthenticateBase from "./authenticate-base";
import * as settings from "../../modules/settings";
import * as rest from "../../modules/rest";
import * as login from "../../modules/login";
import * as loginOidc from "../../modules/login-oidc";
import { useTranslation } from "react-i18next";
import OktaAura from "../../icons/OktaAura";
import OktaLogo from "../../icons/OktaLogo";
import * as validations from "../../lib/validations";
import { DialogActions, DialogContent, useTheme } from "@mui/material";

function AuthenticateExternals({
  adAccount,
  challenge,
  isADAccountNotInGT,
  remember,
  setRemember,
  noRemember,
  requestLoginOidc,
  entraId,
  oktaLogin,
  setOktaLogin,
  loginHint,
  setLoginHint,
  oidcAuthDone,
  oidcProvisioning,
  error,
  setError,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const oa = useOktaAuth();
  const [disabled, setDisabled] = React.useState(false);
  const [loginClicked, setLoginClicked] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [oktaUsername, setOktaUsername] = React.useState(loginHint);
  const isExternalAccountNotInGT =
    isADAccountNotInGT ||
    (error && error.json && error.json.error === "user.not.found");

  if (oktaLogin && oidcProvisioning && oidcProvisioning.type !== "okta") {
    setError(t("user.not.found"));
  }

  const doLogin = useCallback(
    (providerFun) => {
      setDisabled(false);
      setLoginClicked(true);
      if (isADAccountNotInGT) {
        getLoginRequest().prompt = "select_account";
      }
      window.setTimeout(() => providerFun(), 10);
    },
    [setDisabled, setLoginClicked, isADAccountNotInGT]
  );

  const doEntraLogin = useCallback(
    () => doLogin(() => msalInstance.loginRedirect(getLoginRequest())),
    [doLogin]
  );

  const oktaCb = () =>
    doLogin(() => {
      if (!oa) {
        setDialogOpen(true);
      }
      // If we're already logged in to okta, don't redirect there (unless this account isn't a
      // GroupTalk account)
      if (!oa?.authState?.isAuthenticated || isExternalAccountNotInGT) {
        oa?.oktaAuth.signInWithRedirect({
          originalUri: `/login/external?challenge=${challenge}`,
          // loginHint: oktaUsername,
          prompt: isExternalAccountNotInGT ? "login" : undefined,
        });
      }
    });

  const doOktaLogin = useCallback(oktaCb, [
    doLogin,
    oa,
    isExternalAccountNotInGT,
    setDialogOpen,
  ]);

  // If we're logged in to okta, send the token to the auth server to try authenticate
  useEffect(() => {
    if (
      oa?.authState?.isAuthenticated &&
      !isExternalAccountNotInGT &&
      (loginClicked || oidcAuthDone)
    ) {
      requestLoginOidc({
        challenge,
        remember,
        idToken: oa?.authState.idToken.idToken,
      });
    }
  }, [challenge, oa?.authState, loginClicked, oidcAuthDone, remember]);

  // If user requested direct login, do it
  useEffect(() => {
    if (oktaLogin) {
      doOktaLogin();
    }
  }, [oktaLogin, doOktaLogin]);

  const handleClose = () => setDialogOpen(false);

  let errorMsg = <></>;
  if (isADAccountNotInGT) {
    errorMsg = (
      <Alert severity="error">
        {adAccount && (
          <span>
            <span className="account-name">{adAccount}</span>{" "}
            {t("is not a GroupTalk account")}.
          </span>
        )}
        <span>{t("Try again to select alternate account")}</span>
      </Alert>
    );
  }
  return (
    <AuthenticateBase subtitle={(domain) => domain}>
      <Dialog
        open={dialogOpen}
        PaperProps={{
          sx: { background: theme.palette.primary[`${theme.palette.mode}DialogBackground`]},
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            setLoginHint(formJson.oktaUsername);
            setOktaLogin(true);
            handleClose();
          },
        }}
      >
        <DialogTitle>{t("Enter your login id/email")}</DialogTitle>
        <DialogContent>
          <TextField
            style={{ width: "100%" }}
            id="oktaUsername"
            name="oktaUsername"
            autoComplete="email"
            label={t("Your Okta username")}
            required
            fieldProps={{
              validate: validations.composeValidators(
                validations.required,
                validations.email
              ),
            }}
            inputProps={{
              inputMode: "email",
              autoCorrect: "false",
              autoCapitalize: "none",
            }}
            margin="normal"
            value={oktaUsername}
            onChange={(event) => {
              setOktaUsername(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: theme.palette.primary[`${theme.palette.mode}ContrastText`] }}>{t("Dismiss")}</Button>
          <Button
            disableRipple
            variant="contained"
            color="primary"
            className="raisedPrimary flex-60"
            type="submit">
            {t("Login")}
          </Button>
        </DialogActions>
      </Dialog>
      <div
        className="layout-column"
        style={{ width: isMobile ? "100%" : "60%" }}
      >
        {
          /* Don't show Entra login if user explicitly asked for okta */
          !oktaLogin && (
            <div className="layout-row">
              <div className="flex-30" />
              <IconButton
                disabled={disabled}
                onClick={() => doEntraLogin()}
                disableRipple
                variant="contained"
                color="primary"
                className="raisedPrimary"
                style={{ margin: "6px 0px" }}
              >
                <MSLogin
                  viewBox="0 0 104 41"
                  style={{ width: "104px", height: "41px" }}
                />
              </IconButton>
              <div className="flex-30" />
            </div>
          )
        }
        {
          /* Don't show Okta login if user explicitly asked for Entra ID */
          !entraId && (
            <div className="layout-row">
              <div className="flex-30" />
              <Button
                disabled={disabled}
                onClick={() => doOktaLogin()}
                variant="outlined"
                color="primary"
                style={{ backgroundColor: "white", margin: "6px 0px", minWidth: "104px" }}
                className="raisedPrimary"
                startIcon={<OktaAura />}
              >
                <OktaLogo />
              </Button>
              <div className="flex-30" />
            </div>
          )
        }
        {errorMsg}
        {noRemember || (
          <FormControlLabel
            className="layout-align-center-stretch"
            control={
              <Checkbox
                checked={remember}
                onChange={({ target }) => setRemember(target.checked)}
              />
            }
            label={t("Remember me")}
          />
        )}
      </div>
    </AuthenticateBase>
  );
}

export default connect(
  (state) => ({
    adAccount: login.getADAccount(state),
    challenge: rest.getChallenge(state),
    isADAccountNotInGT: login.isADAccountNotInGT(state),
    remember: settings.getRemember(state),
    noRemember: settings.getNoRemember(state),
    oktaLogin: settings.getOkta(state),
    entraId: settings.getAzureAD(state),
    loginHint: settings.getLoginHint(state),
    oidcProvisioning: settings.getOIDCProvisioning(state),
    error: login.getError(state),
    oidcAuthDone: login.getOIDCAuthDone(state),
  }),
  {
    requestLoginOidc: loginOidc.requestLogin,
    setRemember: settings.setRemember,
    setLoginHint: settings.setLoginHint,
    setOktaLogin: settings.setOkta,
    setError: login.setError,
  }
)(AuthenticateExternals);
