import * as rest from '../modules/rest';
import * as settings from '../modules/settings';

export default [
  ({ dispatch }) => next => (action) => {
    next(action);
    // Remember the setting for the future
    if (action.type === settings.REMEMBER_SET) {
      window.localStorage.setItem('remember', action.remember);
    }
    if (action.type === settings.OIDC_PROVISIONING_SET && action.payload) {
      window.localStorage.setItem('oidc-settings', JSON.stringify(action.payload));
    }
    if ((action.type === settings.DOMAIN_HINT_SET || action.type === settings.TENANT_SET) && action.payload) {
      dispatch(rest.getOIDCProvisioningRequest({ tenant: action.payload }));
    }
    if (action.type === settings.LOGIN_HINT_SET && action.payload) {
      dispatch(rest.getOIDCProvisioningRequest({ username: action.payload }));
    }
  },
];
