import { combineActions, handleAction } from 'redux-actions';
import { combineReducers } from 'redux';

const MODULE = 'error';

export const FLAG_ERROR  = `${MODULE} : Flag error`;
export const CLEAR_ERROR = `${MODULE} : Clear error`;

// Actions
export const flagError = payload => ({ type: FLAG_ERROR, payload });
export const clearError = () => ({ type: CLEAR_ERROR });

// Selectors
export const getError = (state) => state[MODULE].error;

// Reducer
export default {
  [MODULE]: combineReducers({
    error: handleAction(combineActions(FLAG_ERROR, CLEAR_ERROR), (_, action) => action.payload || null, null),
  }),
};
