import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { fontSize } from '@mui/system';

// Takes a number of functions T => Object, and returns a function T => Object,
// where the returned object is the merged results of each function.
// If an element is *not* a function, the value itself is used in the merge.
//
// >>> combine(v => ({a: v}), {b: 1})(2)
// { a: 2, b: 1 }
function combine(...fs) {
  return arg => fs.map(f => typeof(f) === 'function' ? f(arg) : f).reduce((acc, r) => Object.assign(acc, r), {});
}

const baseStyles = {
  width:'100%',
  padding: '14px 20px',
};

const titleStyles = {
  width:'100%',
  padding: '5px 10px',
};

const menuStyles = isMobile
  ? ({theme}) => {
    return {
      color: theme.palette.primary.contrastText,
      textAlign: 'left',
      borderBottom: `solid 1px ${theme.palette.grey['600']}`,
    };
  }
  : () => ({});

const color   = f => ({theme}) => ({ color: f(theme) });
const bgColor = f => ({theme}) => ({ backgroundColor: f(theme) });

function CustomTypography(...stylefs) {
  return styled(Typography)(combine(...stylefs));
}

export function MenuTitle({ children, innerRef }) {
  const theme = useTheme();
  const myStyles = isMobile
    ? combine(color(t => t.palette.primary.contrastText), bgColor(t => t.palette.primary.light))
    : () => ({ fontSize: '20px' });
  const Title = CustomTypography(
    titleStyles,
    menuStyles,
    myStyles
  );

  return <div ref={innerRef}><Title component="div">
    {children}
  </Title></div>;
}

export function MenuItem({ icon, path, cmd, children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const MyButton = CustomTypography(
    baseStyles,
    menuStyles,
    { cursor: 'pointer' },
    color(t => t.palette.primary.contrastText));

  const click = () => {
    if (path) {
      navigate(path);
    }
    if (cmd) {
      cmd();
    }
  }

  return <MyButton component="div" onClick={click}>
    <span style={{ position: 'relative', top: '3px', marginRight: '16px', marginLeft: '-4px' }}>{icon}</span>
    <Typography variant="body1" component="span" style={{ position: 'relative', top: '-2px' }}>{children}</Typography>
  </MyButton>;
}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export function Panel({children, style}) {
  const theme = useTheme();
  const MyDiv = CustomTypography(
    baseStyles,
    menuStyles,
    color(t => t.palette.text.primary),
    bgColor(t => t.palette.background.default));
  return <MyDiv component="div" style={style}>
    {children}
  </MyDiv>;
}
