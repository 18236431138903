import React from 'react';
import { useTranslation } from "react-i18next";

function NoPath() {
  const { t } = useTranslation();

  return (
    <div>
      <p>{t('Login by navigating to the appropriate app/service')}</p>
    </div>
  );
}

export default NoPath;
