import moment from 'moment';
import * as login from '../modules/login';
import * as loginOtp from '../modules/login-otp';
import * as rest from '../modules/rest';
import * as settings from '../modules/settings';
import * as error from '../modules/error';
import { getSiteLink } from '../lib/util';

const OTP_RETRY = 10 * 1000; // 10s

function cancelLogin(dispatch) {
  dispatch(rest.rejectAuth({})).then((response) => {
    if (response && response.json && response.json.redirectTo) {
      dispatch(rest.setRedirectTo(response.json.redirectTo));
    }
  });
}

function checkForBadChallenge(dispatch, payload) {
  if (payload.json && payload.json.error === 'authentication.challenge.bad') {
    dispatch(error.flagError(payload.json));
  }
}

// function filterPhone(phone) {
//   return phone.replace(/[- ()]/g, '');
// }
 
export default [
  ({ getState, dispatch }) => next => (action) => {
    next(action);
    if (login.LOGIN_REQUEST === action.type) {
      const remember = settings.getRemember(getState());
      action.body.remember = remember;
      dispatch(rest.authenticate({
        mode: action.mode,
        oob: action.oob,
        body: action.body,
        options: action.options,
      }));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (rest.AUTHENTICATE_SUCCESS === action.type) {
      const { json } = action.payload;
      dispatch(login.setRequire2FA(json.require2FA || false));
      if (json.redirectTo) {
        // If redirectTo is set, follow the link
        dispatch(rest.setRedirectTo(json.redirectTo));
      } else if (json.qrCode) {
        // If qrCode is set, we need to perform 2FA, and the user
        // hasn't set up the account yet
        dispatch(login.totpQrCode(json.qrCode));
      }
      if (json.secret) {
        dispatch(login.setTOTPSecret(json.secret));
      }
      if (json.user) {
        dispatch(login.setUserUuid(json.user));
      }
      if (json.userName) {
        dispatch(login.setUserName(json.userName));
      }
    }
  },
  ({ getState, dispatch }) => next => (action) => {
    next(action);
    if ([rest.AUTHENTICATE_REQUEST].includes(action.type)) {
      dispatch(login.setUnknownCode(false));
    }
    if (rest.AUTHENTICATE_FAILURE === action.type) {
      checkForBadChallenge(dispatch, action.payload);
      if (action.payload.json && action.payload.json.payload && action.payload.json.payload.redirect_to) {
        // If redirectTo is set, follow the link
        dispatch(rest.setRedirectTo(action.payload.json.payload.redirect_to));
        return;
      }
      if (action.payload.json && action.payload.json.error === 'Conflict') {
        cancelLogin(dispatch);
      } else {
        if (action.payload.json && action.payload.json.error === 'user.not.found' && login.getADAccount(getState())) {
          dispatch(login.setADAccountNotInGT(true));
        }
        if (action.payload.json && action.payload.json.error === 'authentication.password.expired') {
          dispatch(login.setPasswordExpired((action.payload.json.payload && action.payload.json.payload.email) || null));
          dispatch(settings.setEmail((action.payload.json.payload && action.payload.json.payload.email) || null));
        }
        if (action.payload.mode === 'onetime-token') {
          if (action.payload.json && action.payload.json.error === 'authentication.code.notPaired') {
            dispatch(login.setUnknownCode(true));

            // Save the retry timer in our redux state, so we can cancel the
            // job if the user leaves the OTP login mode.
            // The check inside the timeout function if we're still in OTP
            // retrying mode is a bit redundant, since we should cancel the
            // timeout when the user leaves the mode.
            const otpTimer = setTimeout(() => login.isRetryingOTP(getState()) && dispatch(loginOtp.requestLogin({ noProgress: true })), OTP_RETRY);
            dispatch(rest.setOTPRetryTimer(otpTimer));
            // dispatch(login.setInfo(action.payload));
          } else {
            dispatch(login.setError(action.payload));
          }
        } else {
          dispatch(login.setError(action.payload));
        }
      }
    }
    if (login.RETRY_OTP === action.type && !action.payload) {
      // When RETRY_OTP is set to false, stop the OTP retry timer
      const timer = rest.getOTPRetryTimer(getState());
      if (timer) {
        clearTimeout(timer);
        dispatch(rest.setOTPRetryTimer(null));
      }
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (rest.AUTHENTICATE_CANCEL_SUCCESS === action.type) {
      const { json } = action.payload;
      dispatch(rest.setRedirectTo(json.redirectTo));
    }
    if (rest.AUTHENTICATE_CANCEL_FAILURE === action.type) {
      if (action.payload.json && action.payload.json.error === 'Conflict') {
        dispatch(rest.setRedirectTo(`${getSiteLink('openid')}/authentication/api/auth/login/cancel?challenge=${action.payload.body.challenge}`));
      } else {
        dispatch(login.setError(action.payload));
      }
    }
  },
  ({ dispatch, getState }) => next => (action) => {
    next(action);
    if (action.type === login.RESEND_OOB) {
      const mode = action.mode;
      const state = getState();
      const extbody = {
        email: mode === 'email' ? settings.getEmail(state) : undefined,
        phone: mode === 'sms' ? settings.getPhone(state) : undefined,
        webOtp: mode === 'sms' ? true : undefined,
      };
      dispatch(rest.resendOOB({
        mode,
        ...extbody,
      }));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.CONSENT_REQUEST) {
      dispatch(rest.consent({ grantedScopes: action.grantedScopes }));
    }
    if (action.type === rest.CONSENT_SUCCESS) {
      dispatch(rest.setRedirectTo(action.payload.redirectTo));
    }
    if (action.type === rest.CONSENT_FAILURE) {
      checkForBadChallenge(dispatch, action.payload);
      if (action.payload.json && action.payload.json.payload && action.payload.json.payload.redirect_to) {
        // If redirectTo is set, follow the link
        dispatch(rest.setRedirectTo(action.payload.json.payload.redirect_to));
        return;
      } else {
        dispatch(login.setError(action.payload));
      }
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.ACQUIRE_OTP_REQUEST) {
      dispatch(rest.acquireOTP({}));
    }
    if (action.type === rest.ACQUIRE_OTP_SUCCESS) {
      action.payload.expires = moment(action.payload.expires);
      dispatch(settings.setOnetimeCode(action.payload));
    }
    if (action.type === rest.ACQUIRE_OTP_FAILURE) {
      dispatch(login.setError(action.payload));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.SET_DOMAIN_FAILURE) {
      dispatch(login.setError(action.payload));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.REDIRECT_TO) {
      const redirect = action.payload;
      if (redirect) {
        dispatch(login.redirecting());
        window.setTimeout(() => {
          window.location.href = redirect;
        }, 100);
      }
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.CONSENT_REJECT_REQUEST) {
      dispatch(rest.consentReject({}));
    }
    if (action.type === rest.CONSENT_REJECT_SUCCESS) {
      dispatch(rest.setRedirectTo(action.payload.redirectTo));
    }
    if (action.type === rest.CONSENT_REJECT_FAILURE) {
      dispatch(login.setError(action.payload));
    }
  },
  ({ dispatch, getState }) => next => (action) => {
    next(action);
    if (action.type === login.SESSION_SET) {
      // Redirect to auth server
      const state = getState();
      const redir = settings.getRedirectUri(state);
      const url = `${getSiteLink('openid')}/authentication/api/auth/session?redirect_uri=${redir}${action.payload ? `&session=${action.payload}` : ''}`;
      dispatch(rest.setRedirectTo(url));
    }
  },
  ({ dispatch, getState }) => next => (action) => {
    next(action);
    if (action.type === login.DOMAIN_SET) {
      // Redirect to auth server
      const state = getState();
      const challenge = rest.getChallenge(state);
      const redir = settings.getRedirectUri(state);
      if (challenge) {
        dispatch(rest.setDomain(action.payload));
      } else {
        const url = `${getSiteLink('openid')}/authentication/api/auth/session?redirect_uri=${redir}&session=cancel&domain=${action.payload.domain}`;
        dispatch(rest.setRedirectTo(url));
      }
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.ABORT_OOB) {
      // Reset the auth code when aborting
      dispatch(settings.setAuthCode(''));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.SET_CHALLENGE) {
      // Validate the challenge
      dispatch(rest.validateChallenge({ challenge: action.challenge }));
    } else if (action.type === rest.VALIDATE_CHALLENGE_FAILURE) {
      if (action.payload.json) {
        if (action.payload.json.payload.redirect_to) {
          // If redirectTo is set, follow the link
          dispatch(rest.setRedirectTo(action.payload.json.payload.redirect_to));
        } else {
          action.payload.json.error = 'authentication.challenge.bad';
          dispatch(error.flagError(action.payload.json));
        }
      } else {
        dispatch(error.flagError({ error: 'unknown', payload: String(action.payload) }));
      }
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === rest.GET_OIDC_PROVISIONING_REQUEST) {
      dispatch(rest.getOIDCProvisioning(action.payload));
    }
    if (action.type === rest.GET_OIDC_PROVISIONING_SUCCESS) {
      dispatch(settings.setOIDCProvisioning(action.payload));
    }
    if (action.type === rest.GET_OIDC_PROVISIONING_FAILURE) {
      dispatch(login.setError(action.payload));
    }
  },
];
