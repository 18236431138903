import i18n from '../i18n';

const VALID_PHONE_REGEXP = /^\+[0-9]{8,20}$/;
const VALID_EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const required = value => (value || typeof value === 'number')
  ? undefined
  : i18n.t('validation.required');
export const phoneNumber = value => value && !VALID_PHONE_REGEXP.test(value)
  ? i18n.t('validation.phone')
  : undefined;
export const email = value => value && !VALID_EMAIL_REGEXP.test(value)
  ? `${i18n.t('validation.email')} (john@doe.com)`
  : undefined;
export const passwordsMustMatch = (value, allValues) => value !== allValues.password
  ? i18n.t('validation.passwordMismatch')
  : undefined

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
