import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getLanguages, getCurrentLanguage } from '../i18n';

function langTerritoryToCountry(lang, territory) {
  switch (lang) {
    case 'en':
      switch (territory) {
        case 'GB':
          return 'GB';
        default:
          break;
      }
      break;
    default:
      break;
  }
  return territory || 'GB';
}

function localeToLanguage(locale) {
  const lang = locale.split(/[-_]/)[0];
  return lang;
}

function localeToCountry(locale) {
  switch (locale) {
    case 'sv':
      return 'SE';
    case 'en':
      return 'GB';
    default: {
      const [lang, territory] = locale.split(/[-_]/);
      return langTerritoryToCountry(lang, territory);
    }
  }
}

function flagClass(locale) {
  return `fi fi-${localeToCountry(locale).toLowerCase()}`;
}

function languageName(lang) {
  // let extraInfo = '';
  // if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
  //   extraInfo = ` (${lang.translated.latest * 100}%)`;
  // }
  return `${lang.nativeName}`;
}

class LanguageSelector extends React.Component {
  state = {
    anchorEl: null,
    languages: [],
  };
  componentDidMount() {
    getLanguages((err, data) => {
      if (!err) {
        let selectedIndex;
        const currentLang = getCurrentLanguage();
        Object.keys(data).forEach((name, ix) => {
          if (name === currentLang) {
            selectedIndex = ix;
          }
        });
        this.setState({ languages: data, selectedIndex });
      }
    });
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleItemClick = (lang, index) => {
    this.setState({ selectedIndex: index, anchorEl: null });
    this.props.i18n.changeLanguage(lang);
  };
  render() {
    const { anchorEl } = this.state;

    return (
      <div style={{ padding: 8 }}>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <span className={flagClass(getCurrentLanguage())} />
          &nbsp;
          <span>{localeToLanguage(getCurrentLanguage())}</span>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {Object.entries(this.state.languages).map(([name, lang], index) => (
            <MenuItem
              key={name}
              value={name}
              selected={index === this.state.selectedIndex}
              onClick={() => this.handleItemClick(name, index)}
            >
              <span className={flagClass(name)} />
              &nbsp;
              <span>{languageName(lang)}</span>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

LanguageSelector.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  i18n: PropTypes.object.isRequired,
};
export default LanguageSelector;
