import { handleAction } from 'redux-actions';
import { combineReducers } from 'redux';

const MODULE = 'decision';

export const SCOPE_SELECT = `${MODULE} : Scope select`;

// Actions
export const setScope = (scope, onoff) => ({ type: SCOPE_SELECT, scope, onoff });

// Selectors
export const getScopes = (state) => state[MODULE].scopes;

// Reducer
export default {
  [MODULE]: combineReducers({
    scopes: handleAction(SCOPE_SELECT, (state, action) => Object.assign({}, state, { [action.scope]: action.onoff }), {}),
  }),
};
