import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import { green } from '@mui/material/colors';
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import * as settings from '../../modules/settings';
import * as rest from '../../modules/rest';
import * as login from '../../modules/login';
import * as loginOtp from '../../modules/login-otp';
import AuthenticationBase from './authenticate-base';

function InfoText({ children }) {
  const theme = useTheme();
  return <Typography variant="body1" component="div" className="info" style={{ backgroundColor: theme.palette.info[theme.palette.mode], color: theme.palette.info.contrastText }}>
    <InfoIcon />{ children }
  </Typography>;
}

function timeToExpiry(expire) {
  return expire.diff(moment()) - 1000;
}

function isValidToken(otp) {
  return otp && timeToExpiry(otp.expires) > 0;
}

function AuthenticateOTP({
  otp,
  requestOTP,
  strict,
  error,
  remember,
  noRemember,
  setRemember,
  user,
  requestLoginOtp,
  isRetryingOTP,
  setRetryingOTP,
  loginMode,
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const buttonSx = {
    ...(user && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  function otpLogin() {
    requestLoginOtp({ noProgress: true });
  }

  // Flag to keep track if we've started a token expire timer
  const [timerStarted, setTimerStarted] = useState(false);

  // Flag set to true if we're busy renewing the token
  const [tokenRenew, setTokenRenew] = useState(false);

  // Flag set to true if we've sent an OTP login. The redux state 'retryingOTP'
  // will be set to true once we start the OTP login process, but it takes a
  // little while to get set to true, so we need a local state here to make
  // sure we don't start multiple login processes in quick succession
  const [loginAttempted, setLoginAttempted] = useState(false);

  // Renew the token, we either have no token, or it has expired
  useEffect(() => {
    if (loginMode === 'otp' && !isValidToken(otp) && !tokenRenew && !error) {
      // We're renewing the token, make sure we only do this once per cycle
      setTokenRenew(true);
      setRetryingOTP(false);
      requestOTP();
    }
  });

  // When we have a valid token, start the login process
  useEffect(() => {
    if (loginMode === 'otp' && isValidToken(otp) && !loginAttempted) {
      // Make sure we don't start the OTP login process more than once
      setLoginAttempted(true);
      otpLogin();
    }
  });

  useEffect(() => {
    if (loginMode !== 'otp') {
      // When we switch login mode, the ongoing OTP login process will
      // stop, so we need to reset our local flag, so we restart it if the
      // user goes back to the OTP login mode.
      setLoginAttempted(false);
    }
  })

  // If we have a valid token, but not yet an expiry timer, start one, so we renew the token
  // when it expires. We're waiting 100ms extra, so we're sure the token is considered expired
  // locally when the timer expires. In reality, the token should not yet be expired at the
  // server, because locally we consider it expired when there's a second left. We do this
  // to ensure we renew the token before it truely expires at the server.
  useEffect(() => {
    if (!timerStarted && isValidToken(otp)) {
      // Make sure we don't start multiple timers
      setTimerStarted(true);

      // Set the timeout function
      setTimeout(() => {
        // We're ready to start a new timer
        setTimerStarted(false);
        // It's time to renew the token
        setTokenRenew(false);
      }, timeToExpiry(otp.expires) + 100);
    }
  });

  function handleRemember(event) {
    setRemember(event.target.checked);
  }

  // Note: calc(50% - 2.3em) adjusts the margins, so we get a centered box big enough for the current font
  return <AuthenticationBase subtitle={domain => domain}>
    <div className="flex">
      {isValidToken(otp) && (<>
        <div>
          {t('Provide code below to your account administrator and wait here until authentication completes')}
        </div>
        <hr />
        <Typography variant="h3" style={{ margin: '10px calc(50% - 2.3em)', padding: '10px 0px', borderRadius: '4px', backgroundColor: theme.palette.secondary[theme.palette.mode], color: theme.palette.secondary.contrastText }}>
          {otp.code}
        </Typography>
        { noRemember || <div>
          <FormControlLabel control={<Checkbox checked={remember} onChange={handleRemember} />} label={t('Remember me')} />
          </div>}
      </>)}
      {!isValidToken(otp) && <InfoText>{t('Allocating an OTP...')}</InfoText>}
    </div>
  </AuthenticationBase>;
}

/*
      <Box sx={{ mt: 4, height: 80, position: 'relative' }}>
        {isValidToken(otp) && isRetryingOTP && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: "calc(50% - 34px)",
              zIndex: 1,
            }}
          />
        )}
      </Box>
*/

export default connect(
  state => ({
    strict: settings.getStrict(state),
    loginMode: login.getLoginMode(state),
    otp: settings.getOnetimeCode(state),
    remember: settings.getRemember(state),
    noRemember: settings.getNoRemember(state),
    isRetryingOTP: login.isRetryingOTP(state),
    error: login.getError(state),
    user: login.getUserUuid(state),
  }),
  {
    requestOTP: rest.requestOTP,
    requestLoginOtp: loginOtp.requestLogin,
    setRetryingOTP: login.setRetryingOTP,
    setRemember: settings.setRemember,
  },
)(AuthenticateOTP);
