import * as settings from '../modules/settings';

export default [
  /*
  ({ dispatch }) => next => (action) => {
    // When redux-form email and phone fields are changed, also update our settings state accordingly
    if (action.type ===  actionTypes.CHANGE && action.meta.form === 'password-login' && action.meta.field === 'username') {
      dispatch(settings.setEmail(action.payload));
    }
    if (action.type ===  actionTypes.CHANGE && action.meta.form === 'emailForm' && action.meta.field === 'email') {
      dispatch(settings.setEmail(action.payload));
    }
    if (action.type ===  actionTypes.CHANGE && action.meta.form === 'smsForm' && action.meta.field === 'phone') {
      dispatch(settings.setPhone(action.payload));
    }
    next(action);
  },
  */
];
