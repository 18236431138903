import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PasswordField from './PasswordField';
import EmailResetCode from './email-reset-code';
import { required, passwordsMustMatch } from '../lib/validations';
import * as resetPassword from '../modules/reset-password';
import * as rest from '../modules/rest';
import * as login from '../modules/login';
import * as settings from '../modules/settings';
import { toErrorMessage } from '../lib/util';

const composeValidators = (...validators) => (value, allValues) =>
  validators.reduce((error, validator) => error || validator(value, allValues), undefined)

function ResetPasswordForm({ onSubmit, disabled, token, resetStatus, resetError }) {
  const { t } = useTranslation();
  return (
    <Form
      onSubmit={onSubmit}
      fields={['password', 'passwordRepeat', 'token']}
      initialValues={{
        token,
      }}
      asyncBlurFields={['password']}>
      {({ handleSubmit }) => (<form
        name="resetCodeForm"
        onSubmit={handleSubmit}
      >
        <div className="flex">
          <div className="layout-align-center-stretch layout-row">
            <Field
              className='flex-85'
              id="password"
              name="password"
              required
              validate={required}
              margin="normal"
            >
              {props => (
                <div>
                  <PasswordField
                    label={t('Password')}
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                </div>
              )}
            </Field>
          </div>
          <div style={{ marginTop: 1 + 'em' }} className="layout-align-center-stretch layout-row">
            <Field
              className='flex-85'
              id="passwordRepeat"
              name="passwordRepeat"
              required
              validate={composeValidators(required, passwordsMustMatch)}
              margin="normal"
              >
              {props => (
                <div>
                  <PasswordField
                    name={props.input.name}
                    label={t('Repeat password')}
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                </div>
              )}
            </Field>
          </div>

          {resetStatus && <>
            <div style={{ marginTop: 1 + 'em' }} className="layout-align-center-stretch layout-row">
              <Alert style={{ width: '25em' }} severity={resetStatus === resetPassword.STATUS_FAIL ? 'error' : 'success'}>{t(`reset.status.${resetStatus}`)}</Alert>
            </div>
            {resetStatus === resetPassword.STATUS_FAIL && resetError && <div className="layout-align-center-stretch layout-row">
              <Alert style={{ width: '25em' }} severity="error">{t(resetError.json.error)}</Alert>
            </div>}
          </>}

          <div style={{ marginTop: 1 + 'em' }} className="layout-align-center-stretch layout-row">
            <Button
              disabled={disabled}
              type="submit"
              disableRipple
              variant="contained"
              color="primary"
              className="raisedPrimary"
              style={{ padding: '0 6px', margin: '6px 8px' }}
            >
              {t('Reset password')}
            </Button>
          </div>

        </div>

      </form>
      )}
    </Form>
  );
}

function validatePassword(translate) {
  return (values, dispatch) => dispatch(rest.validatePassword({
    password: values.password,
    token: values.token,
  })).catch((error) => {
    if (error.json.payload) {
      // There's a list of validation errors here,
      // but we can't split them into multiple lines.
      // The text is escaped before shown on the screen, so
      // HTML doesn't work, and newlines show up as "\n".
      throw { password: error.json.payload[0] };
    } else {
      throw { password: translate(error.json.error) };
    }
  });
}

function ResetPassword({
  token, badToken, resetStatus, resetError,
  doResetPassword, hasChallenge, setLoginMode, setStrict,
}) {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Resetting password should mean the user wants to login using password.
  // However, when clicking on the link in a password reset email, the email
  // address is set through a query parameter, and this has as a side effect
  // to select the 'email' login mode. Set it to 'password' instead.
  setLoginMode('password');
  setStrict(true);

  const handleResetPassword = (values) => {
    doResetPassword(values);
  };

  const errorMsg = title =>
    <div className="flex">
      <div className="layout-align-center-stretch layout-column">
        <h2>{title}</h2>
        <EmailResetCode button={t('Send a new password reset request')} />
      </div>
    </div>;

  if (badToken) {
    let message;
    if (badToken.response && badToken.response.status === 404) {
      // The token wasn't found
      message = t('Bad token');
    } else {
      // Some other error occured
      message = toErrorMessage(t, badToken);
    }
    return errorMsg(message);
  } else if (!token) {
    if (resetStatus === resetPassword.STATUS_SUCCESS) {
      // Changing the password successfully resets the token
      // On mobile, go directly to the password page
      const loginLink = isMobile ? '/login/password' : '/login';
      return <>
        <h2>{t('Your password is updated!')}</h2>
        <p>
          {hasChallenge
            ? <Link to={loginLink}>
              {t('Login')}
            </Link>
            : <p>{t('Login by navigating to the appropriate app/service')}</p>
          }
        </p>
      </>;
    } else {
      return errorMsg(t('Missing token'));
    }
  } else {
    return <>
      <ResetPasswordForm
        asyncValidate={validatePassword(t)}
        onSubmit={handleResetPassword}
        disabled={resetStatus !== '' && resetStatus !== 'fail'}
        token={token}
        resetStatus={resetStatus}
        resetError={resetError} />
    </>;
  }
}


ResetPassword = connect(state =>
({
  token: resetPassword.getResetToken(state),
  badToken: resetPassword.getBadToken(state),
  resetStatus: resetPassword.getResetStatus(state),
  resetError: resetPassword.getResetError(state),
  hasChallenge: rest.getChallenge(state) !== null,
}),
  {
    emailResetCode: resetPassword.emailResetCode,
    doResetPassword: resetPassword.resetPassword,
    setLoginMode: login.setLoginMode,
    setStrict: settings.setStrict,
  }
)(ResetPassword);

export default ResetPassword;
