import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccountCircle from '@mui/icons-material/AccountCircle'
import EditIcon from '@mui/icons-material/Edit';
import SwitchAccount from '@mui/icons-material/SwitchAccount'
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import { useMeasure } from 'react-use';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { MenuTitle } from './util';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import * as rest from '../modules/rest';
import * as login from '../modules/login';
import * as settings from '../modules/settings';

function SelectAccount({ sessions, translateSessions, selectSession, footerHeight, showDomainSelect }) {
  const { t } = useTranslation();
  const currentDomain = window.document.location.host.replace(/^auth\./, '');
  const [domain, setDomain] = useState(currentDomain);
  const [gettingSessions, setGettingSessions] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [ref, { height: titleHeight }] = useMeasure();

  if (!gettingSessions) {
    setGettingSessions(true);
    translateSessions();
  }

  const background = isMobile
    ? theme.palette.primary[`${theme.palette.mode}Gradient`]
    : undefined;
  const borderBottom = isMobile
    ? `solid 1px ${theme.palette.grey['600']}`
    : undefined;

  return <div>
    <MenuTitle innerRef={ref}>
      {t('Select account')}
    </MenuTitle>
    { showDomainSelect && <Typography variant='subtitle2'>
      {currentDomain}
      <IconButton onClick={() => navigate('/server')} size='small'>
        <EditIcon fontSize='inherit' />
      </IconButton>
    </Typography> }
    <Paper sx={{ background, overflow: 'auto', maxHeight: height - titleHeight - footerHeight }}>
      <List sx={{ py: 0 }}>
        {Object.keys(sessions).map(key => <ListItem sx={{ borderBottom }} key={key} className={sessions[key].current ? 'current' : ''}>
          <ListItemButton sx={{ py: 0 }} onClick={() => selectSession(key)}>
            <ListItemIcon>
            { sessions[key].current ? <AccountCircle color="secondary"/> : <AccountCircle /> }
            </ListItemIcon>
            <ListItemText primary={sessions[key].displayName} secondary={sessions[key].organization} />
          </ListItemButton>
        </ListItem>)}
        <ListItem key="_login">
          <ListItemButton sx={{ py: 0 }} onClick={() => selectSession(null)}>
            <ListItemIcon>
                <SwitchAccount />
            </ListItemIcon>
            <ListItemText primary={t('Add account')} secondary={t('Login with a new account')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  </div>
}

SelectAccount = connect(state =>
({
  sessions: login.getSessions(state),
  showDomainSelect: settings.getDomainSelect(state),
}),
  {
    translateSessions: rest.getSessions,
    selectSession: login.setSession,
  }
)(SelectAccount);

export default SelectAccount;
