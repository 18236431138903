import i18n from 'i18next';
import XHR from 'i18next-http-backend';
// import LocizeBackend from 'i18next-locize-backend';
// import LocizeEditor from 'locize-editor';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isMobile } from 'react-device-detect';

// export const locize = new LocizeBackend({
//   projectId: '22a60d4a-6fb8-4da9-80e2-8f19a347201e', // <-- replace with your projectId
//   apiKey: '003dea5e-5a2c-41d2-ae78-cf6685d84b75',
//   referenceLng: 'en',
// });

const SUPPORTED_LANGUAGES = {
  'en': {
    nativeName: 'English',
  },
  'sv': {
    nativeName: 'Svenska',
  },
};

export const getLanguages = (cb) => {
  cb(null, SUPPORTED_LANGUAGES);
};

export function getCurrentLanguage() {
  const supported = Object.keys(SUPPORTED_LANGUAGES);
  return i18n.languages.reduce((supp, lang) =>
     supp || (lang && supported.indexOf(lang) !== -1 ? lang : null), undefined);
}

// On mobile, make sure we don't cache the selected language in localStorage
const mobileOptions = isMobile
  ? {
    detection: {
      order: ['querystring', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: [],
    }    
  }
  : {};

i18n
//  .use(locize)
//  .use(LocizeEditor)
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...mobileOptions,
    backend: {
      loadPath: '/i18n/{{lng}}/{{ns}}.json',
    },
    fallbackLng: 'en',
    appendNamespaceToCIMode: true,
    saveMissing: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    debug: false,
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format(value, format /* , lng */) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
