import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from 'mui-rff';
import { useTranslation } from 'react-i18next';

function PasswordField(props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const fieldprops = {
    ...props,
    type: showPassword ? 'text' : 'password',
    InputProps: {
      endAdornment:
        (<InputAdornment position="end">
          <IconButton
            aria-label={t('Toggle password visibility')}
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            tabIndex={-1}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>)
    },
  };
  return React.createElement(TextField, fieldprops);
}

export default PasswordField;
