import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';
import useWindowDimensions from '../../lib/react-window-dimensions';
import { MenuTitle } from '../util';
import * as settings from '../../modules/settings';

function AuthenticateBase({ subtitle, children, showDomainSelect }) {
  const currentDomain = window.document.location.host.replace(/^auth\./, '');
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const subtitleFunc = typeof(subtitle) === typeof('')
    ? () => subtitle
    : subtitle;

  const heading = (width >= 300)
    ? t('Login to GroupTalk')
    : t('Login');

  return <>
    { isMobile &&
      <MenuTitle>
        { showDomainSelect &&
          <IconButton onClick={() => navigate('/server')} size='large' style={{float: 'right', top: '-10px', paddingBottom: '0px'}}>
            <EditIcon fontSize='inherit' />
          </IconButton>
        }
        <Typography variant='h6'>{heading}</Typography>
        <Typography variant='subtitle2'>{subtitleFunc(currentDomain)}</Typography>
      </MenuTitle>
    }
    { children }
  </>;
}


export default connect(
  state => ({
    showDomainSelect: settings.getDomainSelect(state),
  }),
  {},
)(AuthenticateBase);
