const removeTrailingSlash = s => s.replace(/(.)\/+$/, '$1');

function siteToVar(site) {
  return site.toUpperCase().replace(/[-.]/g, '_');
}

const UNTIL_DOT = /(http[s]?:\/\/)[^.]*(.*)/;

const PORT_RE = /(http[s]?:\/\/[^\/:]+)(:\d+)(.*)/;

function removePort(url) {
  const m = url.match(PORT_RE);
  return m
    ? `${m[1]}${m[3]}`
    : url;
}

export function getSiteLink(site) {
  return removeTrailingSlash(window[siteToVar(site) + '_HOST'] || removePort(window.location.origin).replace(UNTIL_DOT, `$1${site}$2`));
}

export function toErrorMessage(translation, data) {
  const error = data.error || data;
  if (typeof error === typeof '') {
    const vals = error.split('::');
    const key = vals.shift();
    return translation(key, vals);
  } else if (error.json) {
    if (error.json.error_description) {
      return `${translation(error.json.error)}: ${translation(error.json.error_description)}`;
    } else {
      return translation(error.json.error || error.json.message, error.json.payload);
    }
  } else if (error.response) {
    return `${error.response.status}: ${error.response.statusText} (${error.response.url})`
  } else if (error.message) {
    return error.message;
  } else {
    return error;
  }
}

export function linkTo(to, from) {
  const fromUrl = new URL(`http://example.com${from}`);
  return new URL(to, fromUrl).pathname;
}

export function copyToClipboard(str) {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0)
    : false;
  el.select();
  const successful = document.execCommand('copy');
  const msg = successful ? 'success' : 'failure';
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};