import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import * as loginOidc from '../../modules/login-oidc';
import * as rest from "../../modules/rest";
import * as settings from "../../modules/settings";

function OIDCTokenAcquired({ requestLoginOidc, setChallenge }) {
  // The query parameters were restored after we process them in index.js, so we have to pick up the
  // challenge parameter again directly from the query
  const hashQuery = new URL(window.location.hash.substring(1), window.location).search.substring(1);
  const query = new URLSearchParams(hashQuery);
  const challenge = query.get('challenge');
  setChallenge(challenge);
  const remember = settings.getStoredRemember();
  const oa = useOktaAuth();

  if (challenge) {
    // If we're logged in to okta, send the token to the auth server to try authenticate
    if (oa?.authState?.isAuthenticated) {
      requestLoginOidc({
        challenge,
        remember,
        idToken: oa.authState.idToken.idToken,
      });
      return <p>Logging in...</p>
    }
  }
}

OIDCTokenAcquired = connect(
  state => ({}),
  {
    requestLoginOidc: loginOidc.requestLogin,
    setChallenge: rest.setChallenge,
  }
)(OIDCTokenAcquired);

export default OIDCTokenAcquired;