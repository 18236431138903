import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import ContentCopy from '@mui/icons-material/ContentCopy'
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';
import * as login from '../../modules/login';
import { NotificationManager } from 'react-notifications';
import { copyToClipboard } from '../../lib/util';


function TotPQRCode({
  qrCode,
  secret,
  setQRCode,
}) {
  const { t } = useTranslation();
  // On mobile devices, we show the secret code by default,
  // while on other devices, we show the QR code instead.
  // It's possible to toggle the display in either case.
  const [showSecret, setShowSecret] = React.useState(isMobile);

  const copySecret = () => {
    try {
      copyToClipboard(secret);
      NotificationManager.success(t('Secret copied to clipboard'));
    } catch(err) {
      NotificationManager.error(t('Failed to copy secret'), t('Error!'));
    }
  };

  // Show either the secret, or the QR code
  const topView = showSecret
    ? <div style={{display: showSecret}}>
        <h3>{t('Two factor authentication is required')}</h3>
        <Typography sx={{ ml: 1, mr: 1 }}>{t('Copy this secret by clicking on it. Go to your authenticator app, create a new account and paste your secret')}:</Typography>
        <Button variant="outlined" title={t('Copy secret')} sx={{ mt: 1 }} startIcon={<ContentCopy />} onClick={copySecret}>
          <span className="secret">{secret}</span>
        </Button>
      </div>
    : <>
        <h3>{t('Scan the QR Code using your authenticator app')}</h3>
        <img src={qrCode} alt="QR Code"/>
      </>;
  
  // Button to toggle the view between QR code and secret
  const bottomView = <Button onClick={() => setShowSecret(!showSecret)}>
    { t( showSecret ? 'Show QR code' : 'Don\'t have a QR reader? Show secret' ) }
  </Button>;

  return (
    <>
      { topView }

      { bottomView }

      <div style={{ marginTop: '10px' }}>
        <Button
            type="submit"
            disableRipple
            variant="contained"
            color="primary"
            className="raisedPrimary"
            style={{ margin: '6px 0px' }}
            onClick={() => setQRCode(null)}
        >
          {t('Continue to login')}
        </Button>
      </div>
    </>
  );
}

TotPQRCode = connect(
  state => ({}),
  {
    setQRCode: login.totpQrCode,
  },
)(TotPQRCode);
export default TotPQRCode;
