import { handleAction, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';

const MODULE = 'reset-password';

export const EMAIL_RESET_CODE = `${MODULE} : Email reset code`;
export const EMAIL_RESET_SUCCESS = `${MODULE} : Email reset success`;
export const EMAIL_RESET_FAILURE = `${MODULE} : Email reset failure`;
export const SET_EMAIL_RESET_CODE = `${MODULE} : Set email reset code`;
export const SET_RESET_TOKEN = `${MODULE} : set reset token`;
export const SET_BAD_TOKEN = `${MODULE} : set bad token`;
export const RESET_PASSWORD = `${MODULE} : reset password`;
export const RESET_PASSWORD_SUCCESS = `${MODULE} : reset password success`;
export const RESET_PASSWORD_FAILURE = `${MODULE} : reset password failure`;

// Actions
export const emailResetCode = email => ({ type: EMAIL_RESET_CODE, payload: email });
export const emailResetSuccess = () => ({ type: EMAIL_RESET_SUCCESS });
export const emailResetFailure = (error) => ({ type: EMAIL_RESET_FAILURE, payload: error });
export const setResetToken = token => ({ type: SET_RESET_TOKEN, payload: token });
export const setBadToken = (error) => ({ type: SET_BAD_TOKEN, payload: error });
export const resetPassword = ({ password, token }) => ({ type: RESET_PASSWORD, payload: { password, token }});
export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });
export const resetPasswordFailure = (error) => ({ type: RESET_PASSWORD_FAILURE, payload: error });
export const setEmailResetCode = code => ({ type: SET_EMAIL_RESET_CODE, payload: code });
export const resetEmailResetCode = () => setEmailResetCode(STATUS_NONE);

// Selectors
export const getResetToken = state => state[MODULE].token;
export const isBadToken = state => !!state[MODULE].badToken;
export const getBadToken = state => state[MODULE].badToken;
export const getEmailStatus = state => state[MODULE].emailStatus;
export const getEmailError = state => state[MODULE].emailError;
export const getResetStatus = state => state[MODULE].resetStatus;
export const getResetError = state => state[MODULE].resetError;

export const STATUS_NONE    = '';
export const STATUS_SENDING = 'sending';
export const STATUS_SUCCESS = 'success';
export const STATUS_FAIL    = 'fail';

// Reducer
export default {
  [MODULE]: combineReducers({
    token: handleAction(SET_RESET_TOKEN, (_, action) => action.payload, ''),
    badToken: handleAction(combineActions(SET_RESET_TOKEN, SET_BAD_TOKEN),
      (_, action) => action.type === SET_BAD_TOKEN ? action.payload : false, false),
    emailError: handleAction(EMAIL_RESET_FAILURE, (_, action) => action.payload, ''),
    emailStatus: handleAction(combineActions(SET_EMAIL_RESET_CODE, EMAIL_RESET_CODE, EMAIL_RESET_SUCCESS, EMAIL_RESET_FAILURE),
      (_, action) => {
        if (action.type === EMAIL_RESET_CODE) {
          return STATUS_SENDING;
        } else if (action.type === EMAIL_RESET_SUCCESS) {
          return STATUS_SUCCESS;
        } else if (action.type === EMAIL_RESET_FAILURE) {
          return STATUS_FAIL;
        } else if (action.type === SET_EMAIL_RESET_CODE) {
          return action.payload;
        }
      }, STATUS_NONE),
    resetError: handleAction(RESET_PASSWORD_FAILURE, (_, action) => action.payload, ''),
    resetStatus: handleAction(combineActions(RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE),
      (_, action) => {
        if (action.type === RESET_PASSWORD) {
          return STATUS_SENDING;
        } else if (action.type === RESET_PASSWORD_SUCCESS) {
          return STATUS_SUCCESS;
        } else if (action.type === RESET_PASSWORD_FAILURE) {
          return STATUS_FAIL;
        }
      }, ''),
  }),
};
