import login from './login';
import rest from './rest';
import settings from './settings';
import decision from './decision';
import resetPassword from './reset-password';
import error from './error';

export default {
  ...login,
  ...rest,
  ...settings,
  ...decision,
  ...resetPassword,
  ...error,
};
