import * as rest from '../modules/rest';
import * as resetPassword from '../modules/reset-password';
import * as settings from '../modules/settings';

export default [
  ({ dispatch, getState }) => next => (action) => {
    next(action);
    if (action.type === resetPassword.EMAIL_RESET_CODE) {
      const challenge = rest.getChallenge(getState());
      const no_remember = settings.getNoRemember(getState());
      const no_lang = !settings.getShowLang(getState());

      // Normally the email is passed along here. However, in case we're comming from
      // a reset password link, the email is part of that link, and we store it in
      // the state instead of providing an input field.
      const email = action.payload || settings.getEmail(getState());

      const body = {
        challenge,
        email,
        no_remember,
        no_lang
      };
      dispatch(rest.emailResetCode({
        body,
        success: dispatch => dispatch(resetPassword.emailResetSuccess()),
        fail: (dispatch, error) => dispatch(resetPassword.emailResetFailure(action.payload)),
      }));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (action.type === resetPassword.RESET_PASSWORD) {
      dispatch(rest.resetPassword({
        ...action.payload,
        success: dispatch => dispatch(resetPassword.resetPasswordSuccess()),
        fail: (dispatch, error) => dispatch(resetPassword.resetPasswordFailure(error)),
      }));
    } else if (action.type === resetPassword.RESET_PASSWORD_SUCCESS) {
      dispatch(resetPassword.setResetToken(''));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (resetPassword.SET_RESET_TOKEN === action.type && action.payload) {
      const token = action.payload;
      dispatch(rest.validateReset({
        token: token,
        // Only propagate this action if the code validated
        success: () => next(action),
        fail: (dispatch, error) => dispatch(resetPassword.setBadToken(error)),
      }));
    }
  },
  ({ dispatch }) => next => (action) => {
    next(action);
    if (resetPassword.EMAIL_RESET_SUCCESS === action.type && action.payload) {
      const token = action.payload;
      dispatch(rest.validateReset({
        token: token,
        // Only propagate this action if the code validated
        success: () => next(action),
        fail: (dispatch, error) => dispatch(resetPassword.setBadToken(error)),
      }));
    }
  },
];
