import React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField } from 'mui-rff';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { required } from '../../lib/validations';
import PasswordField from '../PasswordField';
import AuthenticateBase from './authenticate-base';
// import EmailResetCode from '../email-reset-code';
import * as settings from '../../modules/settings';
import * as resetPassword from '../../modules/reset-password';
// import { linkTo } from '../../lib/util';


function AuthenticatePassword({ onSubmit, remember, setRemember, noRemember, passwordRenewal }) {
  const { t } = useTranslation();

  return <AuthenticateBase subtitle={(domain) => domain}>
  <Form
    onSubmit={onSubmit}
    render={({ form, handleSubmit }) => <div className="flex">
      <form
        className="flex"
        name="passwordForm"
        onSubmit={handleSubmit}
      >
        <div className="flex">
          <div className="layout-align-center-stretch layout-row">
            <TextField
              tabIndex={1}
              className='flex-85'
              id="username"
              name="username"
              autoComplete="email"
              label={t('Username')}
              required
              fieldProps={{
                validate: required
              }}
              margin="normal"
              inputProps={{
                inputMode: 'email',
                autoCorrect: 'false',
                autoCapitalize: 'none',
              }}
            />
          </div>
          <div className="layout-align-center-stretch layout-row">
            <Field
              id="password"
              name="password"
              margin="normal"
              tabIndex={2}
            >
              {props => (
                <div className="flex-85">
                  <PasswordField
                    autoComplete="current-password"
                    label={t('Password')}
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    required
                    fieldProps={{
                      validate: required
                    }}
                  />
                </div>
              )}
            </Field>
          </div>
          { noRemember || <FormControlLabel control={<Checkbox checked={remember} onChange={({target}) => setRemember(target.checked)} />} label={t('Remember me')} /> }
        </div>
        <div className="layout-row">
          <div className="flex-20" />
          <Button
            type="submit"
            disableRipple
            variant="contained"
            color="primary"
            className="raisedPrimary flex-60"
            style={{ margin: '6px 0px' }}
          >
            {t('Login')}
          </Button>
          <div className="flex-20" />
        </div>
      </form>
      { passwordRenewal && <Link to="/email-reset">
        {t('Forgot password?')}
      </Link> }
    </div>
  } />
  </AuthenticateBase>;
}

export default connect(
  state => ({
    emailStatus: resetPassword.getEmailStatus(state),
    remember: settings.getRemember(state),
    noRemember: settings.getNoRemember(state),
    passwordRenewal: settings.getPasswordRenewal(state),
  }),
  {
    setRemember: settings.setRemember,
  }
)(AuthenticatePassword);
