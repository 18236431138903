import React from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextField } from 'mui-rff';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as validations from '../../lib/validations';
import AuthenticateBase from './authenticate-base';
import * as settings from '../../modules/settings';

function AuthenticateEmail({ onSubmit, strict, email, remember, setRemember, noRemember }) {
  const { t } = useTranslation();
  return <AuthenticateBase subtitle={(domain) => domain}>
    <Form onSubmit={onSubmit} initialValues={{ email }}  render={({ handleSubmit }) => (
      <form
        className="flex"
        name="emailForm"
        onSubmit={handleSubmit}
      >
        <div className="flex layout-align-center-stretch layout-row">
          <TextField
            className='flex-85'
            id="email"
            name="email"
            autoComplete="email"
            label={t('Enter email address to send code to')}
            required
            disabled={strict && email !== ''}
            fieldProps={{
              validate: validations.composeValidators(validations.required, validations.email),
            }}
            inputProps={{
              inputMode: 'email',
              autoCorrect: 'false',
              autoCapitalize: 'none',
            }}
            margin="normal"
          />
        </div>
        { noRemember || <FormControlLabel control={<Checkbox checked={remember} onChange={({target}) => setRemember(target.checked)} />} label={t('Remember me')} /> }
        <div className="layout-row">
          <div className="flex-20" />
          <Button
            type="submit"
            disableRipple
            variant="contained"
            color="primary"
            className="raisedPrimary flex-60"
            style={{ margin: '6px 0px' }}
          >
            {t('Login')}
          </Button>
          <div className="flex-20" />
        </div>
      </form>
    )}/>
  </AuthenticateBase>;
}

export default connect(
  state => ({
    strict: settings.getStrict(state),
    email: settings.getEmail(state),
    remember: settings.getRemember(state),
    noRemember: settings.getNoRemember(state),
  }), {
    setRemember: settings.setRemember,
  }
)(AuthenticateEmail);
