import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import * as settings from '../../modules/settings';
import * as decision from '../../modules/decision';
import * as rest from '../../modules/rest';
import * as login from '../../modules/login';
import { toErrorMessage } from '../../lib/util';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Decision = ({ requestConsent, error, scopes, requestConsentReject }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search && location.search.substring(1));
  const user = query.get('user');
  const client = query.get('client');

  // const scope = query.get('scope');
  // const scopes = typeof scope === typeof '' ? [scope] : (scope || []);
  // const [scopeState, setState] = React.useState(scopes.reduce((obj, sc) => Object.assign(obj, { [sc]: true }), {}));
  const [scopeState, setState] = React.useState(scopes);
  const handleChange = (event) => {
    setState({ ...scopeState, [event.target.name]: event.target.checked });
  };

  const handleSubmit = (event) => {
    // Build an array of the keys with a truthy value
    requestConsent(Object.entries(scopeState).filter(([k,v]) => v).map(([k,v]) => k));
    event.preventDefault();
  };

  const handleRejectConsent = (event) => {
    requestConsentReject();
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="pure-form pure-form-stacked">
        <div className="upper-box">
          <div className="pure-control-group labels">
            <p>{user},</p>
            <p>{query.logo && (<img src={query.logo} alt="Application logo"  />)} <b>{client}</b></p>
            <p>{t('is requesting access to your account')}.</p>
            <p>{t('Do you approve?')}</p>
            <FormControl component="fieldset">
              <FormLabel component="legend">{t('Scopes')}</FormLabel>
              <FormGroup>
                {Object.keys(scopeState).map(scope => (
                  <FormControlLabel
                    key={t(scope)}
                    label={t(scope)}
                    control={
                      <Checkbox
                        name={scope}
                        checked={scopeState[scope]}
                        onChange={handleChange}
                      />
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="success"
              type="submit"
            >
              {t('Accept')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="outlined"
              onClick={handleRejectConsent}
              name="cancel"
            >
              {t('Deny')}
            </Button>
          </Grid>
        </Grid>
        {error && <Alert severity="error">{toErrorMessage(t, error)}</Alert>}
      </form>
    </div>
  );
};

export default connect(
  state => ({
    logo: settings.getLogo(state),
    error: login.getError(state),
    scopes: decision.getScopes(state),
  }),
  {
    requestConsent: rest.requestConsent,
    requestConsentReject: rest.requestConsentReject,
  },
)(Decision);
