import React from 'react';
import Button from '@mui/material/Button';
import { Field, Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MenuTitle, Panel } from './util';
import * as login from '../modules/login';

function SetServer({ setServerDomain }) {
  const currentDomain = window.document.location.host.replace(/^auth\./, '');
  const { t } = useTranslation();
  const navigate = useNavigate();

  function updateDomain({ domain }) {
    if (domain && currentDomain !== domain.toLowerCase()) {
      setServerDomain({ domain: domain.toLowerCase() });
    }
    // Browser back
    navigate(-1);
  }

  return <div id="auth">
    <MenuTitle>
      <Typography variant='h6'>{t('Set server')}</Typography>
      {/* <Typography variant='subtitle2'>{currentDomain}</Typography> */}
    </MenuTitle>
    <Panel style={{ textAlign: 'left' }}>
      <Form onSubmit={updateDomain} initialValues={{ domain: currentDomain }} render={({ handleSubmit }) => (
        <form
          className="flex"
          name="passwordForm"
          onSubmit={handleSubmit}
        >
          <TextField
            style={{ width: 'calc(100% - 80px)' }}
            id="domain"
            name="domain"
            label={t('Server')}
            variant='standard'
            inputProps={{
              placeholder: currentDomain,
              spellCheck: 'false',
              autoCorrect: 'false',
              inputMode: 'email',
              autoCapitalize: 'none',
              pattern: '^((?=[a-zA-Z0-9-]{1,63}\\.)(xn--)?[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\\.)+[a-zA-Z]{2,63}(:[0-9]{1,5})?$',
            }}
            type='text' // Do not use URL, requires schema
            // onChange={event => { setDomain(event.target.value); event.preventDefault(); }}
          />
          <Button type="submit" size='small' variant='contained' style={{ float: 'right', top: 10 }}>{t('Ok')}</Button>
        </form>
      )} />
    </Panel>
  </div>
}

export default connect(
  state => ({}),
  {
    setServerDomain: login.setDomain,
  },
)(SetServer);
