import React from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useReadOTP } from "react-read-otp";
import Anchor from '../Anchor';
import AuthenticateBase from './authenticate-base';
import * as login from '../../modules/login';
import * as rest from '../../modules/rest';
import * as settings from '../../modules/settings';
import { required } from '../../lib/validations';

function AuthenticateOOB({ loginMode, code, setCode, abortOOB, resendOOB, onSubmit, children }) {
  const { t } = useTranslation();
  useReadOTP(setCode);

  function handleResendOOB() {
    // Start listening on OTP messages from SMS
    resendOOB(loginMode);
  }

  return <AuthenticateBase noDomain={true} subtitle={() => ''}>
    <Form onSubmit={onSubmit} initialValues={{ code }} render={({ handleSubmit }) => (
      <form
        className="flex"
        name="oobForm"
        onSubmit={handleSubmit}
      >

        { children }

        <div className="flex layout-align-center-stretch layout-row">
          <TextField
            className='flex-60'
            id="code"
            name="code"
            label={`${t('Enter code received in')} ${t(loginMode)}`}
            required
            fieldProps={{
              validate: required
            }}
            autoComplete="one-time-code"
            margin="normal"
          />
        </div>

        <div className="layout-align-center-stretch layout-row">
          <Button
            disableRipple
            type="submit"
            variant="contained"
            color="primary"
            className="raisedPrimary flex-60"
            style={{ padding: '0 6px', margin: '6px 8px' }}
          >
            {t('Log in')}
          </Button>
        </div>

        <div
          className="layout-align-center-stretch layout-row"
          style={{ marginTop: 10 }}
        >
          <Typography variant="button" sx={{ textTransform: 'unset'}}>
            {t("Didn't receive a code?")}
          </Typography>
          &nbsp;
          <Anchor role="button" color='secondary' onClick={() => handleResendOOB()}>
            <Typography variant="button" sx={{ textTransform: 'unset'}}>
              {t('Resend code')}
            </Typography>
          </Anchor>
        </div>

        <Divider style={{ marginTop: 20 }} />

        <div className="layout-align-center-stretch layout-row" style={{ marginTop: 10 }}>
          <Anchor style={{ fontSize: 12 }} role="button" color='secondary' onClick={abortOOB}>
            {t('Abort')}
          </Anchor>
        </div>
      </form>
    )} />
    </AuthenticateBase>;
}

export default connect(
  state => ({
    loginMode: login.getLoginMode(state),
    code: settings.getAuthCode(state),
  }),
  {
    abortOOB: rest.abortOOB,
    resendOOB: login.resendOOB,
    setCode: settings.setAuthCode,
  },
)(AuthenticateOOB);
