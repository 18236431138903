import React, { useEffect, useState } from 'react';
import LinkButton from '../LinkButton';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as login from '../../modules/login';
import * as rest from '../../modules/rest';
import * as settings from '../../modules/settings';
import AuthenticateRequest from './authenticate-request';
import AuthenticateOOB from './authenticate-oob';
import TotPQRCode from './totp-qrcode';
import Prompt2FACode from './prompt-2fa-code';
import { toErrorMessage } from '../../lib/util';

const LOGIN_TIMEOUT = 1000 * 60 * 58; // 58m

const TIMEOUT_PARAMS = {
  error: 'request_unauthorized',
  error_description: 'The request could not be authorized. The login request has expired. Please try again.',
};

function Authenticate({ isFetching, isAwaitingOOBData, qrCode, totpSecret, require2FA, logo, completeLogin, rejectAuth, verify2FACode, error, info }) {
    const theme = useTheme();
    const { t } = useTranslation();
    const [timeoutStarted, setTimeoutStarted] = useState(false);
    let content;

    useEffect(() => {
      if (!timeoutStarted) {
        setTimeoutStarted(true);
        setTimeout(() => rejectAuth(TIMEOUT_PARAMS), LOGIN_TIMEOUT);
      }
    });

    let infoMsg = info ? <Alert severity='info'>{toErrorMessage(t, info)}</Alert> : null;
    let err = error ? <Alert severity='error'>{toErrorMessage(t, error)}</Alert> : null;

    if (error && error.json && error.json.error === 'authentication.password.expired') {
      err = <div>
        <div>{err}</div>
        <LinkButton to="/email-reset">
          {t('Reset password')}
        </LinkButton>
      </div>
    }
    if (isAwaitingOOBData) {
      content = (
        <AuthenticateOOB onSubmit={completeLogin}>
          {infoMsg}
          {err}
        </AuthenticateOOB>
      );
    } else if (qrCode) {
      content = (
        <TotPQRCode qrCode={qrCode} secret={totpSecret}></TotPQRCode>
      );
    } else if (require2FA) {
      content = (
        <Prompt2FACode onSubmit={(form) => verify2FACode(form.totpcode)}>
          {infoMsg}
          {err}
        </Prompt2FACode>
      );
    } else {
      content = (
        <AuthenticateRequest>
          {infoMsg}
          {err}
        </AuthenticateRequest>
      );
    }
    return (
      <>
        { isFetching && !isFetching.noProgress && (
          <div className="overlay">
            <div className="inner"></div>
            <CircularProgress size={80} thickness={5} />
          </div>
        ) }
        <div id="auth">
          {content}
        </div>
      </>
    );
}

export default connect(
  state => ({
    isAwaitingOOBData: rest.isAwaitingOOBData(state),
    isFetching: rest.isFetching(state),
    qrCode: login.getQrCode(state),
    totpSecret: login.getTOTPSecret(state),
    require2FA: login.requires2FA(state),
    logo: settings.getLogo(state),
    error: login.getError(state),
    info: login.getInfo(state),
  }),
  {
    completeLogin: login.completeLogin,
    verify2FACode: login.verify2FACode,
    rejectAuth: rest.rejectAuth,
  },
)(Authenticate);
